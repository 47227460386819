import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { CustomizedRadios } from "./Radio/RadioButton";
import NumberFormat from "react-number-format";
import DatePicker from "../../datepicker/components/date_picker/date_picker";
import moment from "moment";
import Select from "react-select";
;
export default function AddDiscountModal({ open, close, profileColor, tenant_id }) {
  const inventoryUrl = process.env.REACT_APP_MENUURL;
  const baseURL = process.env.REACT_APP_BASEURL;
  const [dataMenu, setDataMenu] = useState([]);
  const [menuRetrieve, setMenuRetrieve] = useState(false);

  const [dataBody, setDataBody] = useState({
    tenant_id: "",
    discount_type: "percentage", // "percentage", "amount"
    discount_value: null,
    discount_target: "item", // "total", "item"
    item_id: "",
    start_date: "",
    end_date: "",
    limit_per_day: null
  })

  const changeToUnix = (date) => moment(date).unix();
  const changeToDateFormat = (date) => moment(date).format("YYYY-MM-DD");

  async function handleAddItem() {
    const url = baseURL + "/api/discount";

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        ...dataBody,
        tenant_id: tenant_id,
        start_date: changeToDateFormat(dataBody.start_date),
        end_date: changeToDateFormat(dataBody.end_date),
        discount_value: parseInt(dataBody.discount_value.replace(/[,.]/g, ''))
      }),
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toUpperCase() === "SUCCESS") {
          window.location.reload();
        }
      });
  }

  // Get Order Data
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (tenant_id) {
        fetch(inventoryUrl + '/category/' + tenant_id, {
          method: "GET",
          headers: { "content-type": "application/JSON" },
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "SUCCESS") {
              const menu = result.data.length ? result.data.map(item => item.menu.length ? item.menu.map(val => ({
                category_id: item.id,
                ...val
              })) : []).flat().map(item => ({
                value: item.id, label: item.name,
              })) : [];
              setDataMenu(menu);
              setMenuRetrieve(() => true);
            } else {
              setMenuRetrieve(() => false);
            }
          });
      }
    }

    return () => {
      mounted = false;
    };
  }, [tenant_id, menuRetrieve]);

  return (
    <Modal open={open}>
      <Box className="modalbox" style={{ zoom: '80%', height: 'auto' }}>
        <div className="addcategorymodalbox">
          <div className="modaltitle">Add Discount</div>
          <div className="modalform" style={{ margin: '16px 0px' }}>
            <div className="inputlabel" style={{ marginTop: '12px' }}>Discount Target</div>
            <CustomizedRadios
              data={[
                {value: 'total', text: 'Total'},
                {value: 'item', text: 'Item'},
              ]}
              defaultValue={dataBody.discount_target} setStatus={val => setDataBody(state => ({
                ...state,
                discount_target: val,
                item_id: ""
              }))} />
            {
              dataBody.discount_target === 'item'
              ? <>
                  <div className="inputlabel">Select Menu</div>
                  <Select
                    options={dataMenu}
                    onChange={e => setDataBody(state => ({
                      ...state,
                      item_id: e.value
                    }))}
                    isSearchable
                    isClearable
                    isDisabled={menuRetrieve && dataMenu.length ? false : true}
                    placeholder="Search menu" />
                </>
              : null
            }
            <div className="inputlabel" style={{ marginTop: '12px' }}>Discount Type</div>
            <CustomizedRadios
              data={[
                {value: 'percentage', text: 'Percentage'},
                {value: 'amount', text: 'Amount'},
              ]}
              defaultValue={dataBody.discount_type} setStatus={val => setDataBody(state => ({
                ...state,
                discount_type: val,
                discount_value: null
                }))} />
            <div className="inputlabel">Discount Amount</div>
            {
              dataBody.discount_type === 'amount'
              ? <div class="POC" data-placeholder="Rp.">
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    className="inputpricefile"
                    value={dataBody.discount_value}
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator={true}
                    allowNegative={true}
                    onChange={(e) => setDataBody(state => ({
                      ...state,
                      discount_value: e.target.value
                    }))}
                  />
                </div>
            : <div class="POC" data-placeholder="%">
                <input
                  type="number"
                  max={100}
                  className="inputpricefile"
                  onChange={(e) => parseInt(e.target.value ?? '0') <= 100 ? setDataBody(state => ({
                    ...state,
                    discount_value: e.target.value
                  })) : 0}
                />
              </div>
            }
            <div className="inputlabel">Discount Periode</div>
            <div className="promoperiodecontainer">
              <div className="periodeinputlabel">Start</div>
              <DatePicker
                format="ddd, DD MMM "
                value={dataBody.start_date}
                arrow={false}
                editable={false}
                onChange={(value) => setDataBody(state => ({
                  ...state,
                  start_date: new Date(value)
                }))}
              />

              <div className="periodeinputlabel"> &nbsp; End</div>
              <DatePicker
                format="ddd, DD MMM "
                value={dataBody.end_date}
                arrow={false}
                editable={false}
                onChange={(value) => setDataBody(state => ({
                  ...state,
                  end_date: new Date(value)
                }))}
              />
            </div>
            {changeToUnix(dataBody.end_date) <= changeToUnix(dataBody.start_date) ? (
              <div
                style={{
                  marginTop: "-15px",
                  marginBottom: "10px",
                  color: "#df3030",
                }}
              >
                Please choose the right end date
              </div>
            ) : (
              <div style={{ marginBottom: "10px" }}></div>
            )}
            <div className="inputlabel">Discount Limit Per Day</div>
            <div class="POC" data-placeholder="Limit">
              <input
                type="number"
                className="inputpricefile"
                onChange={(e) => setDataBody(state => ({
                  ...state,
                  limit_per_day: e.target.value
                }))}
              />
            </div>
          </div>

          <div className="modalbutton">
            <button
              style={{ color: profileColor }}
              onClick={() => close(false)}
              className="cancelbutton"
            >
              Cancel
            </button>
            <button
              style={{ background: profileColor }}
              type="submit"
              onClick={handleAddItem}
              className="savebutton"
            >
              Add
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}