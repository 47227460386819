import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="tnc-container">
      <div className="title-1">SYARAT DAN KETENTUAN PLATFORM OASIS</div>
      <div className="body-1">
        Syarat dan Ketentuan Platform Oasis ini mengatur penggunaan Platform
        Oasis yang ditawarkan oleh PT ARRA ANAK BANGSA terhadap Pengguna.
        Pengguna dalam hal ini wajib membaca dan memahami dengan seksama Syarat
        dan Ketentuan Platform Oasis ini sebelum mengakses Platform Oasis.
        Dengan Pengguna memilih untuk memakai/menggunakan Platform Oasis,
        Pengguna telah membaca, memahami dan menyetujui seluruh Syarat dan
        Ketentuan Platform Oasis, Kebijakan Privasi Platform Oasis, Syarat dan
        Ketentuan Komersial Bagi Mitra Usaha Terpilih
      </div>

      <div className="title-1">DEFINSI</div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          <span className="tnc-item-title">"Platform Oasis"</span> adalah sebuah
          platform sistem manajemen restoran untuk dine in dan online order yang
          dikembangkan oleh PT ARRA ANAK BANGSA
        </li>
        <li className="list-tnc-item">
          <span className="tnc-item-title">"Badan Usaha" </span> adalah suatu
          badan atau lembaga, baik yang merupakan badan hukum atau bukan badan
          hukum yang menurut peraturan perundang-undangan yang berlaku di
          Republik Indonesia. 
        </li>
        <li className="list-tnc-item">
          <span className="tnc-item-title">"Hari Kerja" </span> adalah hari yang
          merujuk pada hari apa pun di mana operasi bisnis normal dilakukan.
        </li>
        <li className="list-tnc-item">
          <span className="tnc-item-title">"Hari Kalender" </span>adalah waktu
          sehari-semalam selama 24 (dua puluh empat) jam.
        </li>
        <li className="list-tnc-item">
          <span className="tnc-item-title">"OTP (One Time Password)" </span>
          adalah kode sandi yang bersifat unik dan rahasia yang dihasilkan oleh
          Platform Oasis dan dikirimkan melalui media komunikasi, antara lain:
          seperti Short Message Service (SMS), WhatsAapp. Surat elektronik
          (email), ataupun telepon.
        </li>
        <li className="list-tnc-item">
          <span className="tnc-item-title">"Pengguna" </span> adalah perorangan
          maupun Badan yang mengunduh dan untuk selanjutnya membuka dan/atau
          menggunakan fasilitas/layanan yang disediakan oleh Oasis.
        </li>
        <li className="list-tnc-item">
          <span className="tnc-item-title">"Pengembang Platform Oasis" </span>
          adalah PT ARRA ANAK BANGSA
        </li>
      </ol>

      <div className="title-1">KETENTUAN UMUM</div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Platform Oasis hanya dapat digunakan setelah Pengguna menyetujui
          syarat dan ketentuan Aplikasi Oasis dan kebijakan privasi Platform
          Oasis, serta melakukan pendaftaran untuk menggunakan Platform Oasis.
        </li>
        <li className="list-tnc-item">
          Pengguna menyatakan dan menjamin sebagai berikut:
          <ol className="list-tnc">
            <li className="list-tnc-item">
              Pengguna merupakan pihak yang cakap berdasarkan ketentuan
              perundang-undangan yang berlaku untuk menggunakan Platform Oasis.
            </li>
            <li className="list-tnc-item">
              Pengguna menyatakan dan menjamin bahwa Pengguna akan menggunakan
              Platform Oasis secara wajar sesuai dengan syarat dan ketentuan
              Platform Oasis dan ketentuan perundang-undangan yang berlaku di
              Negara Republik Indonesia, serta tidak akan melakukan tindakan
              apapun yang bertujuan untuk menghindari, menembus dan/atau
              memanfaatkan celah sistem keamanan dan/atau menyebabkan gangguan
              dan/atau kerusakan sistem Platform Oasis, maupun yang menyebabkan
              kerugian bagi Platform Oasis dan/atau Pengguna lain dan/atau pihak
              ketiga lainnya. Pengguna bertanggung jawab atas segala konsekwensi
              terhadap ketentuan pelanggaran ketentuan ini.
            </li>
          </ol>
        </li>
        <li className="list-tnc-item">
          Pengguna wajib menjaga kerahasiaan nama akun pengguna (username), kata
          sandi (password), dan OTP (One Time Password). Pengguna setuju untuk
          menggunakan dan menjaga dengan sebaik-baiknya nama akun pengguna
          (username), kata sandi (password), dan OTP (One Time Password)
          tersebut, serta wajib melakukan pemberitahuan melalui sarana
          komunikasi yang disediakan oleh Platform Oasis apabila menemukan atau
          mencurigai bahwa nama akun pengguna (username), kata sandi (password),
          dan OTP (One Time Password) tersebut telah atau mungkin telah
          terungkap kepada pihak yang tidak berhak. 
        </li>
        <li className="list-tnc-item">
          Nama akun pengguna (username), kata sandi (password), dan OTP (One
          Time Password) tidak dapat dipindahtangankan dan dialihkan dalam
          bentuk dan cara apapun juga kepada pihak ketiga/pihak lain, dan tidak
          ditujukan untuk kegiatan yang melanggar ketentuan peraturan
          perundang-undangan yang berlaku.
        </li>
        <li className="list-tnc-item">
          Platform Oasis berhak untuk menolak permohonan registrasi Platform
          Oasis oleh Pengguna.
        </li>
        <li className="list-tnc-item">
          Pengguna wajib menginformasikan melaui sarana komunikasi yang
          disediakan oleh Platform Oasis apabila terdapat perubahan data
          Pengguna antara lain:
          <ol className="list-tnc">
            <li className="list-tnc-item">
              Apabila Pengguna merupakan Badan Usaha : nama Badan Usaha, alamat
              Badan Usaha, legalitas badan usaha, identitas pengurus Badan
              Usaha, nama kontak (contact person), surat elektronik (email),
              nama produk, legalitas produk,jam operasional, nama platform yang
              telah dilekatkan pada perangkat Pengguna, penelusuran informasi,
              maupun data, informasi, keterangan lainnya mengenai kegiatan
              Pengguna di dalam menggunakan Platform Oasis dan yang tergolong
              sebagai Data Pribadi Pengguna.
            </li>
            <li className="list-tnc-item">
              Apabila Pengguna merupakan perseroangan: nama, Kartu Tanda
              Penduduk (KTP), Nomor Pokok Wajib Pajak (NPWP), alamat, nomor
              telepon/handphone, surat elektronik (email), nama produk,
              legalitas produk, jam operasional , nama platform yang telah
              dilekatkan pada perangkat Pengguna, penelusuran informasi maupun
              data, informasi, keterangan lainnya mengenai kegiatan Pengguna di
              dalam menggunakan Platform Oasis dan yang tergolong sebagai Data
              Pribadi Pengguna. Kelalaian Penguna untuk tidak menginformasikan
              hal tersebut merupakan resiko dan tanggung jawab Pengguna
              sepenuhnya.
            </li>
          </ol>
        </li>
        <li className="list-tnc-item">
          Pengguna dengan ini setuju bahwa Platform Oasis memiliki hak mutlak
          untuk memperbaharui, memodifikasi, atau mengubah perangkat lunak yang
          digunakan untuk mengakses Platform Oasis sewaktu-waktu.
        </li>
        <li className="list-tnc-item">
          Untuk kenyamanan Pengguna Platform Oasis berhak untuk menghentikan
          penggunaan Platform Oasis dalam jangka waktu tertentu yang ditentukan
          oleh Oasis untuk keperluan pembaharuan, pemeliharaan atau untuk tujuan
          lain dengan alasan apapun yang dianggap baik oleh Oasis.
        </li>
        <li className="list-tnc-item">
          Pengembang Platform Oasis tidak bertanggung jawab atas segala kerugian
          atau kerusakan yang timbul termasuk namun tidak terbatas hal-hal
          sebagai berikut:
          <ol className="list-tnc">
            <li className="list-tnc-item">
              Kerusakan pada peralatan keras dan lunak dari media elektronik
              yang dimiliki Pengguna.
            </li>
            <li className="list-tnc-item">
              Kerusakan pada peralatan keras dan lunak dari penyedia jasa
              telekomunikasi. 
            </li>
            <li className="list-tnc-item">
              Gangguan dari virus media komunikasi atau komponen-komponen yang
              membahayakan lainnya.
            </li>
            <li className="list-tnc-item">
              Penyalahgunaan nama akun pengguna (username), kata sandi
              (password), dan OTP (One Time Password) dari layanan Platform
              Oasis.
            </li>
            <li className="list-tnc-item">
              Kelalaian Pengguna untuk mengikuti instruksi, prosedur dan
              petunjuk di Platform Oasis.
            </li>
            <li className="list-tnc-item">
              Segala kerugian yang diakibatkan oleh pihak ketiga.
            </li>
            <li className="list-tnc-item">
              Kelalaian Pengguna untuk melakukan pelaporan atas terjadinya
              peristiwa sebagaimana dimaksud dalam butir di atas.
            </li>
          </ol>
        </li>
        <li className="list-tnc-item">
          Platform Oasis berhak menolak secara sepihak atas transaksi yang
          dilakukan Platform Oasis dan/atau atas pertimbangannya sendiri untuk
          menutup akun Pengguna Platform Oasis baik untuk sementara waktu maupun
          secara permanen atas penggunaan Platform Oasis apabila terdapat
          kondisi-kondisi sebagai berikut:
          <ol className="list-tnc">
            <li className="list-tnc-item">
              Ketidakwajaran pada aktivitas atau transaksi penggunaan Platform
              Oasis.
            </li>
            <li className="list-tnc-item">
              Pengguna memberikan data/informasi yang dianggap mencurigakan
              dan/atau memberikan data/informasi palsu/fiktif/tidak valid/tidak
              lengkap/menyesatkan dan/atau tidak tidak bersedia memberikan
              data/informasi apapun yang diminta oleh Platform Oasis sesuai
              ketentuan perundang-undangan yang berlaku.
            </li>
            <li className="list-tnc-item">
              Terdapat permintaan dari instansi yang berwenang sesuai ketentuan
              perundang-undangan yang berlaku.
            </li>
            <li className="list-tnc-item">
              Adanya laporan dari pihak lain kepada Platform Oasis sehubungan
              dengan adanya tindakan mencurigakan dari Pengguna disertai dengan
              bukti-bukti yang dianggap cukup untuk keperluan investigasi.
            </li>
            <li className="list-tnc-item">
              Pengguna perorangan meninggal dunia atau Pengguna dalam bentuk
              Badan mengalami pailit atau mengalami akibat hukum lainnya.
            </li>
            <li className="list-tnc-item">
              Pengguna tidak memenuhi syarat dan ketentuan Platform Oasis.
            </li>
            <li className="list-tnc-item">
              Pengguna tidak memenuhi dan Syarat dan Ketentuan Komersial Bagi
              Mitra Usaha Terpilih.
            </li>
          </ol>
        </li>
        <li className="list-tnc-item">
          Dengan menggunakan Platform Oasis, Pengguna setuju untuk mengizinkan
          Platform Oasis berkomunikasi melalui surat, surat elektronik (email),
          telepon, pesan teks dan pemberitahuan melalui aplikasi seluler atau
          sarana komunikasi lainnya kepada pengguna.
        </li>
        <li className="list-tnc-item">
          Dalam hal Pengguna menyebabkan kerugian kepada Pengguna lainnya atau
          pihak ketiga sehubungan dengan penggunaan Platform Oasis, maka hal
          tersebut menjadi tanggung jawab sepenunya Pengguna yang menyebabkan
          kerugian tersebut dan membebaskan Platform Oasis dari segala akibat
          ganti rugi, tuntutan, gugatan dan akibat hukum lainnya dari pihak
          manapun.
        </li>
        <li className="list-tnc-item">
          Dalam menggunakan Platform Oasis, Pengguna dilarang untuk mengunggah
          atau mempergunakan kata-kata, komentar, gambar, atau konten apapun
          yang mengandung unsur SARA, diskriminasi, merendahkan atau menyudutkan
          orang lain, vulgar, bersifat ancaman, hal-hal lain yang dapat dianggap
          tidak sesuai dengan nilai dan norma sosial, dan bertentangan dengan
          kebijakan yang ditentukan Platform Oasis. Oasis berhak melakukan
          tindakan yang diperlukan atas pelanggaran ketentuan ini, termasuk
          namun tidak terbatas pada penghapusan dan/atau penyesuaian, penutupan
          akun Pengguna secara sementara atau permanan, dan hal-hal yang
          diperlukan lainnya.
        </li>
        <li className="list-tnc-item">
          Platform Oasis milik Pengguna bersifat permanen dengan kondisi-kondisi
          sebagai berikut:
          <ol className="list-tnc">
            <li className="list-tnc-item">
              Akun Penguna tidak ditutup secara permanen, baik oleh Pengguna
              maupun Platform Oasis.
            </li>
            <li className="list-tnc-item">
              Platform Oasis tidak ditutup secara permanen oleh Pengembang
              Platform Oasis.
            </li>
          </ol>
        </li>
        <li className="list-tnc-item">
          Hak atas Kekayaan Intelektual (HAKI) yang berkenaan dengan Platform
          Oasis seluruhnya dilindungi oleh ketentuan perundang-undangan yang
          berlaku di Negara Republik Indonesia. Pengguna mengetahui, mengakui
          dan menyetujui bahwa Hak atas Kekayaan Intelektual (HAKI) yang
          digunakan atau ditampilkan pada Platform Oasis atau setiap Hak atas
          Kekayaan Intelektual (HAKI) milik pihak ketiga yang terdapat dalam
          Platform Oasis, tidak boleh disalin, digunakan, diaplikasikan dan
          diimplementasikan secara keseluruhan atau sebagian, tanpa izin
          tertulis sebelumnya dari Pengembang Platform Oasis dan/atau pihak
          lainnya yang memiliki Hak atas Kekayaan Intelektual yang terdapat
          dalam Platform Oasis.
        </li>
        {/* <li className="list-tnc-item"></li> */}
        {/* <li className="list-tnc-item"></li> */}
      </ol>

      <div className="title-1">LAYANAN PLATFORM OASIS </div>
      <div className="body-1">
        Layanan yang disediakan oleh Platform Oasis saat ini mencakup:
      </div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Website Dashboard Admin Tenant (termasuk di dalamnya fitur order
          status screen, tables, orders, promo banner, inventory, customer dan
          layanan terkait lainnya)
        </li>
        <li className="list-tnc-item">
          Website order makanan dan minuman untuk customer dari pengguna
        </li>
      </ol>

      <div className="title-1">BIAYA JASA PENGGUNAAN PLATFORM OASIS</div>
      <div className="body-1">
        Biaya jasa pengunaan Platform Oasis akan diatur lebih lanjut di dalam
        dokumen Syarat dan Ketentuan Komersial Bagi Mitra Usaha Terpilih yang
        ditandatangani oleh Penyedia Platform Oasis dan Pengguna selaku Mitra
        Usaha Terpilih dan merupakan satu kesatuan yang tidak terpisahkan dari
        Syarat dan Ketentuan Platform Oasis ini.
      </div>

      <div className="title-1">PERLINDUNGAN DATA</div>
      <div className="body-1">
        Pengguna setuju bahwa pengumpulan, pemanfaatan dan penyerahan data yang
        disampaikan oleh Pengguna kepada Platform Oasis akan dilakukan dengan
        tunduk kepada syarat dan ketentuan dan kebijakan privasi yang terdapat
        pada halaman yang berbeda yang menjadi satu kesatuan yang tidak
        terpisahkan dengan syarat dan ketentuan ini.
      </div>

      <div className="title-1">KEADAAN MEMAKSA</div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Tidak dilaksanakannya atau tertundanya pelaksanaan sebagaian atau
          keseluruhan kewajiban berdasarkan syarat dan ketentuan oleh Platform
          Oasis tidak akan dianggap sebagai pelanggaran terhadap syarat dan
          ketentuan apabila hal tersebut disebabkan oleh suatu peristiwa yang
          berada di luar kendali Platform Oasis atau lazim disebut dengan
          keadaan istilah keadaan memaksa (force majeure) termasuk namun tidak
          terbatas kebakaran besar, bencana alam, gempa bumi, banjir besar,
          huru-hara, pemogokan buruh, gangguan sistem, perang, wabah, epdiemi,
          pandemi, adanya keputusan instansi yang bewenang yang berdampak pada
          penyediaan layanan Platform Oasis.
        </li>
        <li className="list-tnc-item">
          Sehubungan dengan poin 1 diatas, dengan ini Pengguna menyetujui untuk
          melepaskan Platform Oasis dari ganti rugi, tuntutan, gugatan dan
          akibat hukum lainnya.
        </li>
      </ol>

      <div className="title-1">
        HUKUM YANG BERLAKU DAN PENYELESAIAN PERSELISIHAN{" "}
      </div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Syarat dan ketentuan ini tunduk dan diatur serta dilaksanakan sesuai
          dengan hukum di Negara Republik Indonesia.
        </li>
        <li className="list-tnc-item">
          Perbedaan pendapat dan perselisihan yang mungkin timbul diantara
          Pengguna dan Platform Oasis di dalam melaksanakan syarat dan ketentuan
          ini akan diselesaikan secara musyawarah untuk mufakat.
        </li>
        <li className="list-tnc-item">
          Apabila dalam jangka waktu 30 (tiga) Hari Kalender tidak tercapai
          mufakat, maka Pengguna dan Platform Oasis setuju untuk menyampaikan
          dan menyelesaikan secara akhir di Badan Arbitrase Nasional Indonesia
          (BANI) di Wahana Graha lantai 1 dan 2, Jl Mampang Prapatan No. 2
          Jakarta Selatan, Indonesia. Arbitrase akan dilakukan dengan peraturan
          arbitrase BANI dalam Bahasa Indonesia. Majelis arbitrase akan terdiri
          dari tiga orang arbiter, satu dipilih oleh Penggugat, satu dipilih
          oleh Tergugat dan ketua arbiter dipilih oleh para arbiter yang
          ditunjuk oleh Penggugat dan Tergugat. Jika para arbiter Penggugat dan
          Tergugat gagal menunjuk ketua arbiter dalam waktu 10 (sepuluh) hari
          kalender dihitung sejak penunjukkan pertama arbiter oleh Penggugat,
          ketua arbiter akan ditunjuk oleh BANI. Hasil putusan dari majelis
          arbitrase ini adalah bersifat final dan mengikatdiantara Para Pihak.
        </li>
      </ol>

      <div className="title-1">LAIN-LAIN </div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Apabila terdapat bagian dari syarat dan ketentuan Platform Oasis perlu
          disesuaikan berdasarkan ketentuan perundang-undangan yang berlaku,
          maka ketentuan lain dari syarat dan ketentuan Platform Oasis akan
          tetap berlaku sepenuhnya.
        </li>
        <li className="list-tnc-item">
          Platform Oasis setiap saat berhak mengubah, melengkapi atau mengganti
          syarat dan ketentuan Platform Oasis dengan menyampaikan dan meminta
          pembaruan (update) kepada Pengguna melalui Platform Oasis.
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
