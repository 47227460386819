import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import TablePagination from "../../Pagination/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import TopBar from "../TopBar/TopBar";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ThreeDots } from "react-loader-spinner";
import { SocketContext } from "../../socketContext";
import "../TopBar/TopBar.css";
import "./DiscountPage.css";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import addedOrderSound from "../../sounds/order_added_sound.wav"
import waiterCalledSound from "../../sounds/waiter_called_sound.wav"
import AddDiscountModal from "./AddDiscountModal";
import EditDiscountModal from "./EditDiscountModal";
import DeleteDiscountModal from "./DeleteDiscountModal";

function DiscountPage({ tenant }) {
  const [page, setPage] = useState(0);
  const rowsPerPage = 7;
  const [index, setIndex] = useState(1);
  let history = useHistory();
  const [addDiscountModal, setAddDiscountModal] = useState(false);
  const [editDiscountModal, setEditDiscountModal] = useState(false);
  const [deleteiscountModal, setDeleteDiscountModal] = useState(false);
  const localUrl = process.env.REACT_APP_BASEURL;
  const [discountRetrieve, setDiscountRetrieve] = useState(false);
  const [discountData, setDiscountData] = useState([]);
  const [itemId, setItemId] = useState("");

  // Get Tenant Data
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const url = localUrl + "/api/discount";
      fetch(url, {
        method: "GET",
        headers: { "content-type": "application/JSON" },
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.status.toUpperCase() === "SUCCESS") {
            setDiscountData([result.data]);
            setDiscountRetrieve(() => true);
          } else {
            setDiscountRetrieve(() => false);
          }
        });
    }
    return () => {
      mounted = false;
    };
  }, [tenant, discountRetrieve]);

  const tenantUrl = process.env.REACT_APP_TENANTURL;
  const [tenantData, setTenantData] = useState([]);
  const [tenantRetrieved, setTenantRetrieved] = useState(false);
  const [profileName, setProfileName] = useState();
  const [profileColor, setProfileColor] = useState();

  // Get Tenant Data
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (tenant.tenant_id != undefined) {
        const url = tenantUrl + "/user/" + tenant.tenant_id;
        fetch(url, {
          method: "GET",
          headers: { "content-type": "application/JSON" },
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "SUCCESS") {
              setTenantData([result.data]);
              setTenantRetrieved(() => true);
            } else {
              setTenantRetrieved(() => false);
            }
          });
      }
    }
    return () => {
      mounted = false;
    };
  }, [tenant, tenantRetrieved]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (tenantRetrieved === true) {
        setProfileName(tenantData[0].name);
        setProfileColor(tenantData[0].profileColor);
      }
    }
    return () => {
      mounted = false;
    };
  }, [tenantRetrieved, tenantData]);

  function TablePaginationActions(props) {
    const { count, page, onPageChange } = props;

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
      setIndex(index - 7);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);

      setIndex(index + 7);
    };

    return (
      <div className="containerbutton">
        <button
          onClick={handleBackButtonClick}
          disabled={page === 0}
          className={page === 0 ? "leftdisabledbutton" : "leftdisplaybutton"}
        >
          
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={page === 0 ? { color: "#BEBEBE" } : { color: "#949494" }}
          />
        </button>

        <button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / 7) - 1}
          className={
            page >= Math.ceil(count / 7) - 1
              ? "rightdisabledbutton"
              : "rightdisplaybutton"
          }
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            style={
              page >= Math.ceil(count / 7) - 1
                ? { color: "#BEBEBE" }
                : { color: "#949494" }
            }
          />
        </button>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - discountData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <div className="container" style={{ zoom:"60%" }}>
      <div className="topbar">
        <div className="left" style={{ color: profileColor }}>
          Discount
        </div>

        <TopBar />
      </div>

      <div onClick={() => history.push("/orderstatus")}>
        <ToastContainer progressClassName="toastProgress" toastStyle={{ backgroundColor: `${profileColor}`, color: "#fff", fontWeight: "600" }} />
      </div>

      {discountRetrieve ? (
        <div className="customercontainer">
          <div className="outercustomertable">
            <div className="customertable">
              <div className="customerheader">
                <div className="customerleft" style={{ color: profileColor }}>
                  All Discount
                </div>
                <div className="customerright">
                  <button
                    className="downloadbutton"
                    style={{ backgroundColor: profileColor }}
                    onClick={() => setAddDiscountModal(true)}
                  >
                    Add Discount
                  </button>
                </div>
              </div>
              <div className="discountheadertitlegrid">
                <div className="customerheadertitle">NO</div>
                <div className="customerheadertitle">DISCOUNT ITEM</div>
                <div className="customerheadertitle">DISCOUNT VALUE</div>
                <div className="customerheadertitle">PERIODE</div>
                <div className="customerheadertitle">LIMIT PER DAY</div>
                <div className="customerheadertitle">ACTION</div>
              </div>

              <div className="customerrendercontainer">
                {(rowsPerPage > 0
                  ? discountData[0].slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : discountData
                ).map((post, i) => {
                  const orderDateStart = new Date(post.start_date);
                  const orderDateEnd = new Date(post.end_date);

                  return (
                    <div className={i != 7 ? "bordered" : "noborder"}>
                      <div className="discountrendergrid">
                        <div className="customertext">{i + index}</div>
                        <div className="customertext">{post.item_detail?.name || post.discount_target}</div>
                        <div className="customertext">
                          {
                            post.discount_type === 'percentage'
                            ? `${post.discount_value} %`
                            : `Rp. ${post.discount_value.toLocaleString("id-ID")}`
                          }
                        </div>
                        <div className="customertext">
                          {orderDateStart.toLocaleDateString("en-ID", dateOptions)}&nbsp;-&nbsp;
                          {orderDateEnd.toLocaleDateString("en-ID", dateOptions)} 
                        </div>
                        <div className="customertext">{post.limit_per_day}</div>
                        <div className="customertext">
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{ color: profileColor, marginRight: '12px', cursor: 'pointer' }}
                            onClick={() => {
                              setEditDiscountModal(true);
                              setItemId(() => post._id)
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{ color: profileColor, cursor: 'pointer' }}
                            onClick={() => {
                              setDeleteDiscountModal(true);
                              setItemId(() => post._id)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="footer">
            <TablePagination
              colSpan={3}
              count={discountData[0].length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <ThreeDots color={profileColor} height={80} width={80} />
        </div>
      )}
    <AddDiscountModal
      open={addDiscountModal}
      profileColor={profileColor}
      close={(val) => setAddDiscountModal(val)}
      tenant_id={tenant.tenant_id} />
    {
      itemId
      ? <EditDiscountModal
        open={editDiscountModal}
        profileColor={profileColor}
        close={(val) => {
          setEditDiscountModal(val);
          setItemId("");
        }}
        tenant_id={tenant.tenant_id}
        id={itemId} />
      : null
    }
    {
      itemId
      ? <DeleteDiscountModal
        open={deleteiscountModal}
        profileColor={profileColor}
        close={(val) => {
          setDeleteDiscountModal(val);
          setItemId("");
        }}
        id={itemId} />
      : null
    }
    </div>
  );
}

const mapStateToProps = ({ session }) => ({
  tenant: session.user,
});

export default connect(mapStateToProps)(DiscountPage);
