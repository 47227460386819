import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
   faCheck
  } from "@fortawesome/free-solid-svg-icons";
import "./RadioButton.css";

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 18,
  height:18,

  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 2px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#ffff',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px solid #c4c4c4',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<div className="checkedicon"><FontAwesomeIcon icon={faCheck} /></div>}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export function CustomizedRadios( {setStatus, defaultValue, data}) {
return (
  <FormControl>
    <RadioGroup
      defaultValue={defaultValue}
    >
      {
        data.map(item => (
          <FormControlLabel key={item.value} value={item.value} control={<BpRadio />} 
            onChange={(e)=> { 
              setStatus(e.target.value);
            }} 
            label={<span className='radiogroup'>{item.text}</span>}
            />
        ))
      }
    </RadioGroup>
  </FormControl>
);
}