import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
;
export default function DeleteDiscountModal({ open, close, profileColor, id }) {
  const baseURL = process.env.REACT_APP_BASEURL;
  async function handleDeleteItem() {
    const url = baseURL + "/api/discount/" + id;

    fetch(url, {
      method: "DELETE",
      headers: { "content-type": "application/JSON" },
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status.toUpperCase() === "SUCCESS") {
          window.location.reload();
        }
      });
  }

  return (
    <Modal open={open}>
      <Box className="modalbox" style={{ zoom: '80%', height: 'auto' }}>
        <div className="addcategorymodalbox">
          <div className="modaltitle">Are you sure to remove discount ?</div>

          <div className="modalbutton">
            <button
              style={{ color: profileColor }}
              onClick={() => close(false)}
              className="cancelbutton"
            >
              Cancel
            </button>
            <button
              style={{ background: profileColor }}
              type="submit"
              onClick={handleDeleteItem}
              className="savebutton"
            >
              Remove
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  )
}