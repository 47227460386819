import React from "react";

const PrivacyPolicy = () => {
  const emailadmin = process.env.REACT_APP_ADMINEMAIL;
  return (
    <div className="tnc-container">
      <div className="title-1">KEBIJAKAN PRIVASI PLATFORM OASIS</div>
      <p className="body-1">
        merupakan sebuah platform sistem manajemen restoran untuk dine in dan online order yang 
        dikembangkan  oleh PT ARRA ANAK BANGSA. Kebijakan Privasi ini adalah
        perjanjian antara Platform Oasis dan Pengguna (di dalam dokumen Syarat
        dan Ketentuan Komersial Bagi Mitra Usaha Terpilih disebut Mitra Usaha)
        yang berisikan ketentuan mengenai Data Pribadi Pengguna (untuk
        selanjutnya disebut dengan <b>"Kebijakan Privasi"</b>).
      </p>
      <p className="body-1">
        Platform Oasis menghormati hal-hal yang berkenaan dengan perlindungan
        privasi Pengguna. Oleh karena itu, Platform Oasis menyusun Kebijakan
        Privasi ini untuk menjelaskan kepada Pengguna bagaimana Platform Oasis
        memperoleh, mengumpulkan, mengolah, menganalisis, menggunakan,
        menyimpan, menampilkan, mengumumkan, mengirimkan, menyebarluaskan,
        menghapus dan memusnahkan Data Pribadi Pengguna, baik pada saat
        melakukan registrasi, mengakses, serta menggunakan layanan
        Platform Oasis. Pengguna wajib membaca Kebijakan Privasi ini dengan
        seksama untuk memastikan bahwa Pengguna telah memahami bagaimana proses
        pengolahan Data Pribadi Pengguna tersebut. Kecuali diatur lain,
        istilah-istilah dalam huruf kapital yang digunakan di dalam Kebijakan
        Privasi ini memiliki arti yang sama dengan yang terdapat dalam Syarat
        dan Ketentuan Platform Oasis.  Syarat dan Ketentuan Komersial Bagi Mitra
        Usaha Terpilih dan merupakan bagian yang tidak terpisahkan dari
        Kebijakan Privasi ini.
      </p>
      <p className="body-1">
        Dengan menggunakan Platform Oasis, Pengguna telah memahami
        dan menyetujui Kebijakan Privasi, Syarat & Ketentuan Oasis, Syarat
        dan Ketentuan Komersial Bagi Mitra Usaha Terpilih.
      </p>

      <div className="title-1">I. DATA PRIBADI PENGGUNA.</div>
      <p className="body-1">
        Data Pribadi Pengguna adalah data tentang Pengguna yang teridentifikasi
        atau dapat diidentifikasi secara tersendiri atau dikombinasi dengan
        informasi lainnya baik secara langsung maupun tidak langsung melalui
        sistem elektronik atau non-elektronik. Data Pribadi Pengguna dalam hal
        ini memuat setiap data, informasi, keterangan dalam bentuk apapun yang
        dapat mengidentifikasikan diri Pengguna, yang dari waktu ke waktu
        Pengguna sampaikan kepada Platform Oasis atau yang Pengguna cantumkan
        atau sampaikan melalui Platform Oasis yang menyangkut informasi yang
        mencakup antara lain:
      </p>

      <ol className="list-tnc">
        <li className="list-tnc-item">
          Apabila Pengguna merupakan Badan Usaha:  nama Badan Usaha, alamat
          Badan Usaha, legalitas badan usaha, identitas pengurus Badan Usaha,
          nama kontak (contact person), surat elektronik (email), nama produk,
          legalitas produk,jam operasional, nama platform yang telah dilekatkan
          pada perangkat Pengguna, penelusuran informasi, maupun data,
          informasi, keterangan lainnya mengenai kegiatan Pengguna di dalam
          menggunakan Platform Oasis dan yang tergolong sebagai Data Pribadi
          Pengguna.
        </li>
        <li className="list-tnc-item">
          Apabila Pengguna merupakan perseorangan: nama, Kartu Tanda Penduduk
          (KTP), Nomor Pokok Wajib Pajak (NPWP), alamat, nomor
          telepon/handphone, surat elektronik (email), nama produk, legalitas
          produk, jam operasional , nama platform yang telah dilekatkan pada
          perangkat Pengguna, penelusuran informasi maupun data, informasi,
          keterangan lainnya mengenai kegiatan Pengguna di dalam menggunakan
          Platform Oasis dan yang tergolong sebagai Data Pribadi Pengguna.
        </li>
      </ol>

      <div className="title-1">II. KEAKURATAN DATA PRIBADI PENGGUNA. </div>
      <p className="body-1">
        Platform Oasis membutuhkan Data Pribadi Pengguna salah satunya adalah
        untuk dapat melakukan registrasi dan pemrosesan yang berhubungan dengan
        layanan yang disediakan oleh Platform Oasis. Oleh karena itu, Data
        Pribadi Pengguna yang diberikan kepada Platform Oasis harus benar dan
        akurat sesuai fakta yang ada, serta dapat dipertanggungjawabkan.
        Pengguna wajib memperbaharui dan memberitahukan kepada Platform Oasis
        apabila terdapat perubahan terhadap Data Pribadi Pengguna.
      </p>

      <div className="title-1">III. PENGUMPULAN DATA PRIBADI PENGGUNA.</div>
      <p className="body-1">
        Platform Oasis akan mengumpulkan Data Pribadi Pengguna pada saat:
      </p>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Pengguna melakukan pendaftaran dan pengkinian data.
        </li>
        <li className="list-tnc-item">
          Pengguna mengakses Platform Oasis atau pada saat lainnya sebagaimana
          Platform Oasis mintakan kepada Pengguna apabila dibutuhkan dari waktu
          ke waktu. 
        </li>
      </ol>

      <div className="title-1">IV. PENGGUNAAN DATA PRIBADI PENGGUNA. </div>
      <p className="body-1">
        Platform Oasis akan mengolah, menganalisis, dan/atau menggunakan Data
        Pribadi Pengguna untuk tujuan sebagai berikut maupun tujuan lain yang
        diizinkan oleh peraturan perundang-undangan yang berlaku:
      </p>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Mengidentifikasi dan mendaftarkan Pengguna sebagai Pengguna Platform
          Oasis, serta melakukan validasi/verifikasi, menonaktifkan,
          menghapuskan atau mengelola akun Pengguna dan otentikasi
          penggunaan Platform Oasis;
        </li>
        <li className="list-tnc-item">
          Memproses layanan yang terdapat di Platform Oasis;
        </li>
        <li className="list-tnc-item">
          Mencegah, mendeteksi, menyelidiki dan mengatasi segala bentuk dan
          jenis atas tindakan yang merupakan kejahatan, dilarang, ilegal, tidak
          sah atau curang, yang mungkin terjadi dalam penggunaan Platform Oasis,
          termasuk namun tidak terbatas pada hal-hal yang berhubungan dengan
          potensi dan/atau temuan atas penipuan fraud, penggelapan, pencurian
          dan pencucian uang, serta terorisme, pelanggaran Undang-Undang
          Informasi Transaksi Elektronik dan peraturan perundang-undangan/hukum
          positif terkait lainnya (sebagaimana dimungkinkan); Platform Oasis
          juga dapat menggunakan metode penilaian sendiri untuk melakukan
          penilaian tersebut, hal mana tidak perlu dibuktikan;
        </li>
        <li className="list-tnc-item">
          Pelaksanaan riset mengenai data demografis Pengguna Platform Oasis
          termasuk namun tidak terbatas pada penggunaan layanan Platform Oasis;
        </li>
        <li className="list-tnc-item">
          Mengembangkan, meningkatkan, dan menyediakan layanan, fitur, promosi
          yang terdapat di dalam Platform Oasis;
        </li>
        <li className="list-tnc-item">
          Pengiriman informasi yang Platform Oasis anggap berguna untuk Pengguna
          termasuk informasi tentang produk, layanan, dan promosi dari Platform
          Oasis;
        </li>
        <li className="list-tnc-item">
          Tujuan administratif internal, seperti; audit, analisis data,
          rekaman-rekaman dalam database;
        </li>
        <li className="list-tnc-item">
          Berkomunikasi dengan Pengguna sehubungan dengan segala hal mengenai
          Platform Oasis, seperti layanan, fitur dan promosi;
        </li>
        <li className="list-tnc-item">
          Menjaga keselamatan, keamanan, kepatuhan dan keberlangsungan Platform
          Oasis;
        </li>
        <li className="list-tnc-item">
          Pada saat Pengguna menggunakan gambar dan/atau kata Pengguna yang
          terdapat di Platform Oasis, dalam hal ini ditujukan untuk hal-hal yang
          terkait dengan layanan Platform Oasis;
        </li>
        <li className="list-tnc-item">
          Berkomunikasi dengan Pengguna sehubungan dengan layanan Platform Oasis
          melalui saluran yang berbeda (misalnya, melalui telepon dan surat
          elektronik/email);
        </li>
        <li className="list-tnc-item">
          Menampilkan iklan berbasis minat yang mungkin relevan bagi Pengguna;
        </li>
        <li className="list-tnc-item">
          Memenuhi ketentuan perundang-undangan yang berlaku.
        </li>
      </ol>

      <div className="title-1">
        V. PENGUNGKAPAN DATA PRIBADI PENGGUNA KEPADA PIHAK KETIGA.
      </div>
      <p className="body-1">
        Untuk tujuan pengembangan, peningkatan, perlindungan, pemeliharaan,
        layanan, fitur, promosi, kerjasama, dalam hal ini Platform Oasis
        terkadang diperlukan untuk menampilkan, mengumumkan, mengirimkan,
        dan/atau menyebarluaskan Data Pribadi Pengguna kepada pihak ketiga.
        Untuk menghindari keragu-raguan, pihak ketiga tersebut dalam hal ini
        termasuk namun tidak terbatas pada:
      </p>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Perusahaan afiliasi Platform Oasis untuk atau sehubungan dengan tujuan
          yang terkait dengan penyediaan Platform Oasis, pengelolaan bisnis dan
          kegiatan lainnya;
        </li>
        <li className="list-tnc-item">
          Agen, vendor, pemasok, kontraktor atau pihak yang mengadakan kerja
          sama komersial dengan Platform Oasis dengan tujuan untuk mendukung
          Platform Oasis;
        </li>
        <li className="list-tnc-item">
          Pihak ketiga apabila terdapat transaksi atau aksi korporasi Pengembang
          Platform Oasis, seperti: penggabungan, pemisahan, pengambilalihan,
          peleburan atau pembubaran; Platform Oasis akan memberitahukan kepada
          Pengguna baik sebelum maupun sesudah terjadinya keadaan tersebut;
        </li>
        <li className="list-tnc-item">
          Otoritas/institusi yang berwenang sebagaimana disyaratkan atau
          diperintahkan oleh peraturan perundang-undangan yang berlaku.
        </li>
      </ol>

      <div className="title-1">VI. PENYIMPANAN DAN KEAMANAN DATA. </div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Platform Oasis memastikan bahwa Data Pribadi Pengguna yang dikumpulkan
          dan/atau terkumpul oleh Platform Oasis akan disimpan dengan aman
          sesuai dengan peraturan perundang-undangan yang berlaku di Indonesia.
          Platform Oasis akan menyimpan Data Pribadi Pengguna untuk memenuhi
          tujuan yang dijelaskan dalam Kebijakan Privasi ini sesuai dengan
          ketentuan perundang-undangan yang berlaku.
        </li>
        <li className="list-tnc-item">
          Platform Oasis akan memberitahukan kepada Pengguna dalam hal terjadi
          kegagalan perlindungan Data Pribadi Pengguna sesuai dengan ketentuan
          perundang-undangan yang berlaku. 
        </li>
      </ol>

      <div className="title-1">VIII. AKSES TIDAK SAH.</div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Pengguna wajib untuk menjaga kerahasiaan Data Pribadi Pengguna, akun
          Pengguna dan kata sandi (password) yang Pengguna buat dan gunakan
          untuk mengakses Platform Oasis dan tidak membiarkan pihak ketiga untuk
          dapat mengakses Data Pribadi Pengguna, akun Pengguna (username) kata
          sandi (password)
        </li>
        <li className="list-tnc-item">
          Pengguna dalam hal ini bertanggung jawab atas penyalahgunaan dan/atau
          kelalaian sehubungan dengan angka 1 diatas.
        </li>
        <li className="list-tnc-item">
          Pengguna wajib memberitahukan kepada Platform Oasis sehubungan dengan
          angka 1 diatas.
        </li>
      </ol>

      <div className="title-1">
        IX. PERMINTAAN AKSES, SALINAN, PERUBAHAN DATA PRIBADI PENGGUNA.
      </div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Pengguna dapat meminta akses, salinan Data Pribadi Pengguna dan/atau
          meminta perubahan Data Pribadi Pengguna berdasarkan Kebijakan Privasi
          ini, Syarat dan Ketentuan Platform Oasis dan ketentuan
          perundang-undangan yang berlaku.
        </li>
        <li className="list-tnc-item">
          Platform Oasis berhak menolak permintaan sebagaimana angka 1 diatas
          berdasarkan Kebijakan Privasi ini, Syarat dan Ketentuan Platform Oasis
          dan ketentuan peraturan perundang-undangan yang berlaku.
        </li>
      </ol>

      <div className="title-1">
        X. PENCABUTAN PERSETUJUAN, PENGAKHIRAN PENGGUNAAN DATA PRIBADI PENGGUNA.
      </div>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Pengguna dapat meminta Platform Oasis untuk menarik kembali
          persetujuan penggunaan Data Pribadi Pengguna yang telah diberikan.
          Dalam hal ini Pengguna memahami dan menyetujui bahwa penarikan
          persetujuan tersebut tidak berdampak pada setiap hal yang terjadi
          sebelum penarikan persetujuan penggunaan Data Pribadi Pengguna.
        </li>
        <li className="list-tnc-item">
          Pengguna dapat meminta Platform Oasis untuk mengakhiri pemrosesan,
          menghapus dan/atau memusnahkan Data Pribadi Pengguna. 
        </li>
        <li className="list-tnc-item">
          Pemberitahuan sehubungan dengan angka 1 dan 2 diatas dilakukan melalui
          surat elektronik (email) Admin Oasis di mitra@oasis.co.id
        </li>
        <li className="list-tnc-item">
          Dalam hal terdapat konsekuensi sehubungan dengan angka 1 dan 2 diatas
          yang mengakibatkan penghentian layanan Platform Oasis kepada Pengguna,
          maka Platform Oasis akan memberitahukan hal tersebut kepada Pengguna.
        </li>
      </ol>

      <div className="title-1">XII. HUKUM YANG BERLAKU </div>
      <p className="body-1">
        Kebijakan Privasi ini diatur dan ditafsirkan sesuai dengan hukum di
        Negara Republik Indonesia.
      </p>

      <div className="title-1">XIII. PERUBAHAN TERHADAP KEBIJAKAN PRIVASI.</div>
      <p className="body-1">
        Platform Oasis dapat untuk setiap saat mengubah, memperbarui,
        dan/atau menambahkan sebagian maupun seluruh ketentuan dalam Kebijakan
        Privasi ini. Apabila Kebijakan Privasi ini
        diubah/diperbaharui/ditambahkan maka Platform Oasis akan
        memberitahukan kepada Pengguna sehubungan dengan hal tersebut. 
      </p>

      <div className="title-1">XIV. PERNYATAAN DAN PERSETUJUAN PENGGUNA. </div>
      <p className="body-1">
        Pengguna dengan ini menyatakan dan menyetujui hal-hal sebagai berikut:
      </p>
      <ol className="list-tnc">
        <li className="list-tnc-item">
          Bahwa pemberian Data Pribadi Pengguna kepada Platform Oasis merupakan
          data yang benar dan sah, oleh karenanya Pengguna memberikan
          persetujuan kepada Platform Oasis untuk memperoleh, mengumpulkan,
          mengolah, menganalisis, menyimpan, menampilkan, mengumumkan,
          mengirimkan, menyebarluaskan, menghapus dan memusnahkan se
        </li>
        <li className="list-tnc-item">
          Bahwa Pengguna telah membaca, memahami dan menyetujui seluruh
          ketentuan yang terdapat dalam Kebijakan Privasi ini.
        </li>
        <li className="list-tnc-item">
          Seluruh Syarat dan Ketentuan Platform Oasis mengikat dan menjadi satu
          kesatuan yang tidak terpisahkan dari Kebijakan Privasi ini.
        </li>
        <li className="list-tnc-item">
          Bahwa Pengguna membebaskan Platform Oasis dari setiap bentuk tuntutan,
          keberatan, aduan, gugatan, ganti rugi, klaim dan segala akibat hukum
          sehubungan dengan kegagalan penggunaan layanan Platform Oasis yang
          disebabkan oleh ketidakakuratan/ketidakbenaran Data Pribadi Pengguna
          berikan kepada Platform Oasis.
        </li>
        <li className="list-tnc-item">
          Bahwa Pengguna telah memberikan persetujuan, izin, dan wewenang kepada
          Pengguna sehubungan dengan pengungkapan Data Pribadi Pengguna kepada
          pihak ketiga (termasuk pihak terkait lainnya yang bekerja untuk dan di
          bawah pengawasan Platform Oasis) sebagaimana dimaksud dalam Kebijakan
          Privasi ini.
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
